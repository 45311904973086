export const THEME_DARK = "theme-dark";
export const THEME_LIGHT = "theme-light";

class Colorizer {
  public currentTheme: string = THEME_LIGHT;
  private colorizeElements: NodeList;
  private lastTheme: string;

  constructor() {}

  initialize() {
    this.update();
  }

  update() {
    this.colorizeElements = document.querySelectorAll(".colorize");
  }

  changeColor(theme: string) {
    this.lastTheme = this.currentTheme;
    this.update();
    this.colorizeElements.forEach((el: HTMLElement) => {
      el.classList.remove(THEME_DARK, THEME_LIGHT);
      if (theme == THEME_DARK) {
        el.classList.add(THEME_DARK);
      } else if (theme == THEME_LIGHT) {
        el.classList.add(THEME_LIGHT);
      }
    });
    this.currentTheme = theme;
  }

  changeColorBack() {
    if (this.lastTheme) {
      this.changeColor(this.lastTheme);
    }
  }
}

export const colorizer = new Colorizer();
