import { colorizer, THEME_DARK, THEME_LIGHT } from "../modules/Colorizer";
import { SlidePage } from "./SlidePage";
import { LIGHT } from "../utils/Contants";

export class ContactPage extends SlidePage {
  public element: HTMLElement;

  constructor(element) {
    super(element);
  }

  public animateIn() {
    colorizer.changeColor(this.textColor == LIGHT ? THEME_LIGHT : THEME_DARK);
    return super.animateIn();
  }

  public animateOut() {
    colorizer.changeColorBack();
    return super.animateOut();
  }
}
