import Emitter from "@hellomonday/emitter";

interface Callback {
  (result: any): void;
}

export const DROPDOWN_SELECT = "dropdown_select";

export class Dropdown extends Emitter {
  public element: HTMLElement;
  public selectedKey: "";
  private selected: HTMLElement;
  private button: HTMLElement;
  private list: HTMLElement;
  private listItems: NodeListOf<HTMLElement>;
  private dropdown: HTMLElement;
  private callback: Callback;

  constructor(element: HTMLElement) {
    super();
    this.element = element;
    this.selected = this.element.querySelector(".selected button");
    this.button = this.element.querySelector(".button");
    this.list = this.element.querySelector(".list");
    this.listItems = this.list.querySelectorAll(".list-item");
    this.dropdown = this.element.querySelector(".dropdown");

    this.init();
  }

  private init() {
    this.button.addEventListener("click", e => this.toggleDropdown(e));
    this.addListItemHandlers();
  }

  onListItemClick(e: any) {
    const el = e.target;
    const title = el.querySelector(".dropdown-title").innerHTML;
    this.updateSelectedItem(el.querySelector(".dropdown-title").innerHTML);
    this.selectedKey = el.dataset.value;
    this.emit(DROPDOWN_SELECT, { value: this.selectedKey, title });
    this.hideDropdown();
  }

  toggleDropdown(e: any) {
    if (this.element.classList.contains("active")) {
      this.hideDropdown();
    } else {
      this.showDropdown();
    }
  }

  setItemTemplate(key, label, sublabel) {
    return `
				<li class="list-item">
				<button data-value="${key}">
				<span class="span dropdown-title type-p--small">${label}</span>
				<span class="span dropdown-subtitle type-p--small">${sublabel}</span>
				</button>
				</li>
		`;
  }

  hide() {
    this.element.style.display = "none";
  }

  show() {
    this.element.style.display = "block";
  }

  hideDropdown() {
    this.element.classList.remove("active");
  }

  showDropdown() {
    this.element.classList.add("active");
  }

  addListItemHandlers() {
    this.listItems.forEach(el => {
      el.addEventListener("click", e => this.onListItemClick(e));
    }, this);
  }

  removeListItemHandlers() {
    this.listItems.forEach(el => {
      el.removeEventListener("click", e => this.onListItemClick(e));
    }, this);
  }

  public removeItem(value) {
    const button = this.element.querySelector(
      "button[data-value=" + value + "]"
    );
    button.removeEventListener("click", e => this.onListItemClick(e));
    button.parentNode.removeChild(button);
  }

  public updateSelectedItem(value) {
    const buttonTitle = this.selected.querySelector(".dropdown-title");
    buttonTitle.innerHTML = value;
  }

  public populate(values: any) {
    this.removeListItemHandlers();
    this.selectedKey = "";
    this.list.innerHTML = "";
    for (const item of values) {
      let listItem = this.setItemTemplate(item.key, item.label, item.sublabel);
      this.list.insertAdjacentHTML("beforeend", listItem);
    }
    this.selected.querySelector(".dropdown-title").innerHTML = values[0].label;
    this.listItems = this.list.querySelectorAll(".list-item");
    this.addListItemHandlers();
  }

  public close() {
    this.hideDropdown();
  }

  public destroy() {
    this.button.removeEventListener("click", e => this.toggleDropdown(e));
    this.removeListItemHandlers();
  }
}

export class DropdownItem {
  public key: string;
  public label: string;
  public sublabel: string;

  constructor(key: string, label: string, sublabel: string = "") {
    this.key = key;
    this.label = label;
    this.sublabel = sublabel;
  }
}
