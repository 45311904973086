import { EditorialModule } from "./EditorialModule";

export class SketchfabModule extends EditorialModule {
  private viewButton: HTMLElement;
  private closeIcon: HTMLElement;
  private controls: HTMLElement;
  private iframe: HTMLElement;

  constructor(element: HTMLElement) {
    super(element);
    this.controls = this.element.querySelector(".sketchfab-controls");
    this.closeIcon = this.element.querySelector(".close-icon");
    this.closeIcon.addEventListener("click", () => {
      this.close();
    });
    this.iframe = this.element.querySelector("#api-frame");
    this.viewButton = this.element.querySelector(".pill-button");
    this.viewButton.addEventListener("click", () => {
      this.initialize();
    });
  }

  initialize() {
    this.viewButton.style.display = "none";
    this.closeIcon.style.display = "block";

    // Sketchfab Viewer API: Custom Load Progress
    const version = "1.9.0";
    const uid = this.element.dataset.sketchfabid; //'988c46f8fc5f48e7b02737ca68b0ccf2';

    //@ts-ignore
    const client = new window.Sketchfab(version, this.iframe);

    const error = function() {
      console.error("Sketchfab API error");
    };

    const success = api => {
      const pbmodel: HTMLElement = this.element.querySelector("#pb-model");

      api.addEventListener("modelLoadProgress", eventData => {
        const percent = Math.floor(eventData.progress * 100);
        pbmodel.style.width = percent + "%";
        if (percent == 100) {
          this.controls.style.display = "none";
        }
      });

      this.controls.style.display = "block";
      pbmodel.style.width = "0";

      // api.addEventListener('textureLoadProgress', (eventData) => {
      // 	const percent = Math.floor(eventData.progress * 50) + 50;
      // 	pbmodel.style.width = percent + '%';
      // });

      // api.addEventListener('viewerready', function() {
      //
      // });

      // autostart
      //api.start();
    };

    client.init(uid, {
      success: success,
      error: error,
      preload: 0,
      autostart: 1,
      ui_hint: 0,
      ui_infos: 0,
      ui_stop: 0,
      ui_color: "FF0000",
      ui_controls: 0,
      ui_loading: 0,
      ui_watermark: 0,
      ui_watermark_link: 0
    });
  }

  close() {
    this.iframe.setAttribute("src", "");
    this.viewButton.style.display = "block";
    this.closeIcon.style.display = "none";
  }
}
