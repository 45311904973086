import Emitter from "@hellomonday/emitter";
import { View } from "../View";

export class EditorialModule extends View {
  public pageEl: HTMLElement;
  public pageCloseButtonEl: HTMLElement;
  public mediaElement: HTMLMediaElement;

  constructor(element) {
    super(element);
    this.pageEl = document.querySelector(".EditorialPage");
    this.pageCloseButtonEl = this.pageEl.querySelector(".close-button");
    this.element = element;
    const images = this.element.querySelectorAll("img");
    images.forEach(img => {
      img.addEventListener("contextmenu", event => event.preventDefault());
      img.setAttribute("draggable", "false");
    });
  }

  public play() {}

  public pause() {}

  public onScroll(scrollTop: number) {}

  public render() {}

  public onResize() {}
}
