//Keep typescript from complaining about touch test.
declare class DocumentTouch {}
export class Globals {
  public static DEBUG: boolean = false;
  public static SITE_WRAPPER: HTMLDivElement = document.getElementById(
    "SiteWrapper"
  ) as HTMLDivElement;
  public static TEMPLATE_LAYER = document.getElementById(
    "TemplateLayer"
  ) as HTMLDivElement;
  public static IS_IE: boolean = false;
  public static MAIN_LOGO: SVGSVGElement;
  public static BP_MD: number = 800;
}
