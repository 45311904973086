import Emitter from "@hellomonday/emitter";
import { gsap } from "gsap";

export const TICK = "tick";
export const WHOOSH = "whoosh6";
export const WHOOSH_DOOR = "whoosh8";
export const SILENCE = "silence";

export const MUSIC_MUTE_CHANGE = "music_mute_change";
export const SFX_MUTE_CHANGE = "sfx_mute_change";

export class AudioController extends Emitter {
  public audioCtx: AudioContext;
  private audioItems: { [key: string]: AudioItem } = {};
  private musicAudioItems: { [key: string]: AudioItem } = {};
  private sfxAudioItems: { [key: string]: AudioItem } = {};
  private _sfxMuted: boolean;
  private _musicMuted: boolean;

  constructor() {
    super();
  }

  private init() {
    // @ts-ignore
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();

    const assets = {};
    assets[TICK] = "/assets/audio/tick.mp3";
    assets[WHOOSH] = "/assets/audio/whoosh6.mp3";
    assets[WHOOSH_DOOR] = "/assets/audio/whoosh8.mp3";

    for (const assetId in assets) {
      const url = assets[assetId];
      this.audioItems[assetId] = new AudioItem(assetId, url);
    }

    this.audioItems[TICK].maxGain = -0.8;
    this.audioItems[WHOOSH].maxGain = -0.9;
    this.audioItems[WHOOSH_DOOR].maxGain = -0.5;
  }

  public async load() {
    this.init();
    await Promise.all(
      Object.keys(this.audioItems).map(async assetId => {
        return new Promise(resolve => {
          const audioItem = this.audioItems[assetId];
          if (audioItem.useBuffer) {
            const request = new XMLHttpRequest();
            request.open("GET", audioItem.url);
            request.responseType = "arraybuffer";
            const self = this;
            request.onload = function() {
              let undecodedAudio = request.response;
              self.audioCtx.decodeAudioData(
                undecodedAudio,
                (data: AudioBuffer) => (audioItem.buffer = data)
              );
              resolve();
            };
            request.send();
          } else {
            resolve();
          }
        });
      })
    );
  }

  public play(id: string, delay: number = 0) {
    const audioItem = this.get(id);
    if (!audioItem) return;
    if (delay == 0) {
      audioItem.play();
    } else {
      gsap.delayedCall(delay, () => {
        audioItem.play();
      });
    }
  }

  public pause(id: string, delay: number = 0) {
    const audioItem = this.get(id);
    if (!audioItem) return;
    gsap.delayedCall(delay, () => {
      audioItem.pause();
    });
  }

  public fadeIn(id: string, delay: number = 0, duration: number = 0.5) {
    const audioItem = this.get(id);
    if (!audioItem) return;
    gsap.delayedCall(delay, () => {
      audioItem.fadeIn(duration);
    });
  }

  public fadeOut(
    id: string,
    delay: number = 0,
    duration: number = 0.5,
    pauseOnComplete: boolean = true
  ) {
    const audioItem = this.get(id);
    if (!audioItem) return;
    gsap.delayedCall(delay, () => {
      audioItem.fadeOut(duration, pauseOnComplete);
    });
  }

  public get(id: string) {
    return this.audioItems[id];
  }

  public stopAll() {
    Object.keys(this.audioItems).forEach(id => {
      this.audioItems[id].pause();
    });
  }

  public set musicMuted(isMuted: boolean) {
    this._musicMuted = isMuted;
    Object.keys(this.musicAudioItems).forEach(id => {
      this.musicAudioItems[id].mute(isMuted);
    });
    this.emit(MUSIC_MUTE_CHANGE, { isMuted: isMuted });
  }

  public get musicMuted() {
    return this._musicMuted;
  }

  public set sfxMuted(isMuted: boolean) {
    this._sfxMuted = isMuted;
    Object.keys(this.sfxAudioItems).forEach(id => {
      this.sfxAudioItems[id].mute(isMuted);
    });
    this.emit(SFX_MUTE_CHANGE, { isMuted: isMuted });
  }

  public get sfxMuted() {
    return this._sfxMuted;
  }

  public pauseAll() {
    if (this.audioCtx) {
      this.audioCtx.suspend();
    }
  }

  public resumeAll() {
    if (this.audioCtx) {
      this.audioCtx.resume();
    }
  }
}

export class AudioItem {
  public id: string;
  public url: string;
  public audioElement: HTMLAudioElement;
  public buffer: AudioBuffer;
  public sourceNode: AudioBufferSourceNode;
  public gainNode: GainNode;
  public useBuffer: boolean = true;
  public isPlaying: boolean = false;
  public muted: boolean = false;
  private _maxGain: number = 0;

  constructor(id: string, url: string) {
    this.id = id;
    this.url = url;

    this.gainNode = audioController.audioCtx.createGain();
    this.gainNode.connect(audioController.audioCtx.destination);
  }

  public set maxGain(value: number) {
    this._maxGain = value;
    if (!this.muted) {
      this.gainNode.gain.value = value;
    }
  }

  public get maxGain() {
    return this._maxGain;
  }

  play() {
    if (this.buffer) {
      this.sourceNode = audioController.audioCtx.createBufferSource();
      this.sourceNode.buffer = this.buffer;
      this.sourceNode.connect(audioController.audioCtx.destination);
      this.sourceNode.connect(this.gainNode);
      this.sourceNode.start(0);
    } else {
      if (!this.audioElement) {
        this.audioElement = new Audio(this.url);
        this.audioElement.preload = "auto";
        const source = audioController.audioCtx.createMediaElementSource(
          this.audioElement
        );
        source.connect(this.gainNode);
        source.connect(audioController.audioCtx.destination);
      }
      this.audioElement.play();
    }
    this.isPlaying = true;
    if (!this.muted) {
      this.gainNode.gain.value = this.maxGain;
    }
  }

  pause() {
    if (this.sourceNode) {
      this.sourceNode.disconnect();
      this.sourceNode.stop(0);
      this.sourceNode = null;
    } else {
      if (this.audioElement) {
        this.audioElement.pause();
      }
    }
    this.isPlaying = false;
  }

  fadeIn(duration: number = 0.5) {
    if (this.muted) {
      return;
    }
    gsap.to(this.gainNode.gain, duration, { value: this.maxGain });
  }

  fadeOut(duration: number = 0.5, pauseOnComplete: boolean = true) {
    gsap.to(this.gainNode.gain, duration, {
      value: -1,
      onComplete: () => {
        if (pauseOnComplete) {
          this.pause();
        }
      }
    });
  }

  mute(isMuted: boolean) {
    this.muted = isMuted;
    this.gainNode.gain.value = isMuted ? -1 : this.maxGain;
  }
}

export const audioController = new AudioController();
