import { BASE_PATH } from "../utils/Contants";
import { introPage } from "../templates/IntroPage";

class ShowData {
  public json: any = { show: {} };
  public students: Array<any> = [];

  public async load() {
    //@ts-ignore show_id is a var in Main.html.twig that originates from .env file
    const showResponse = await fetch(BASE_PATH + "show-data-json/" + show_id);

    let index = 0;
    if (showResponse.ok) {
      this.json.show = await showResponse.json();
      introPage.progress = 0.1;
      const programmeSlugs = this.json.show.programmeSlugs;
      for (const programmeSlug of programmeSlugs) {
        const programmeUrl =
          BASE_PATH + "programme-data-json/" + programmeSlug.slug;
        const programmeResponse = await fetch(programmeUrl);
        if (programmeResponse.ok) {
          this.json.show.programmes.push(await programmeResponse.json());
          introPage.progress =
            (index / (programmeSlugs.length - 1)) * 0.4 + 0.1;
          index++;
        }
      }
    }
    this.createQuickReferences();
  }

  private createQuickReferences() {
    let unitCount = 0;
    const temptStudentDict = {};
    this.json.show.programmes.forEach(programme => {
      programme.units.forEach(unit => {
        unit.show = "";
        unit.programme = programme;
        unit.index = unitCount;
        unit.projects.forEach((project, index) => {
          project.programme = programme;
          project.unit = unit;
          project.index = index;
          project.students.forEach((student, index) => {
            if (!temptStudentDict[student.name]) {
              this.students.push(student);
              temptStudentDict[student.name] = true;
            }
            student.project = project;
          });
        });
        unitCount++;
      });
    });
  }

  public getUnitBySlug(unitSlug: string) {
    let cUnit = null;
    this.json.show.programmes.forEach(programme => {
      programme.units.forEach(unit => {
        if (unit.slug == unitSlug) {
          cUnit = unit;
        }
      });
    });
    return cUnit;
  }

  public getProjectBySlug(projectSlug: string) {
    let cProject = null;
    this.json.show.programmes.forEach(programme => {
      programme.units.forEach(unit => {
        unit.projects.forEach(project => {
          if (project.slug == projectSlug) {
            cProject = project;
          }
        });
      });
    });
    return cProject;
  }

  public getStudents(name: string = null) {
    const results = [];
    this.students.forEach(student => {
      const sName = student.name.toLowerCase();
      if (!name || sName.indexOf(name.toLowerCase()) > -1) {
        results.push(student);
      }
    });
    return results;
  }

  public getProjectsByAwardType(awardType: string = null) {
    const results = [];
    const studentsDict = {};
    const projectsDict = {};
    this.getProjectsByProgrammeAndUnit().forEach(project => {
      //add student awards
      project.students.forEach(student => {
        if (student.awards) {
          student.awards.forEach(award => {
            if (
              !studentsDict[student.name] &&
              (!awardType ||
                award.type.toLowerCase().replace(/&amp;/g, "&") ==
                  awardType.toLowerCase())
            ) {
              const projectClone = Object.assign({}, project);
              projectClone.students = [student];
              studentsDict[student.name] = student;
              results.push(projectClone);
            }
          });
        }
      });
      //add project awards
      if (project.awards) {
        project.awards.forEach(award => {
          if (
            !projectsDict[project.title] &&
            (!awardType ||
              award.type.toLowerCase().replace(/&amp;/g, "&") ==
                awardType.toLowerCase())
          ) {
            projectsDict[project.title] = project;
            results.push(project);
          }
        });
      }
    });
    return results;
  }

  public getProjectsByProgrammeAndUnit(
    programmeSlug: string = null,
    unitSlug: string = null
  ) {
    const results = [];
    this.json.show.programmes.forEach(programme => {
      if (
        !programmeSlug ||
        programme.slug.toLowerCase() == programmeSlug.toLowerCase()
      ) {
        programme.units.forEach(unit => {
          unit.projects.forEach(project => {
            if (
              !unitSlug ||
              unit.slug.toLowerCase() == unitSlug.toLowerCase()
            ) {
              results.push(project);
            }
          });
        });
      }
    });
    return results;
  }

  public getProjectsByProgrammeAndGroup(
    programmeSlug: string,
    groupValue: string
  ) {
    const results = [];
    this.getUnitsByProgramme(programmeSlug).forEach(unit => {
      unit.projects.forEach(project => {
        if (
          !groupValue ||
          project.groupValue.replace("&amp;", "&").toLowerCase() ==
            groupValue.toLowerCase()
        ) {
          results.push(project);
        }
      });
    });
    return results;
  }

  public getProgrammesByShow() {
    const results = [];
    this.json.show.programmes.forEach(programme => {
      results.push(programme);
    });
    return results;
  }

  public getUnitsByProgramme(programmeSlug: string = null) {
    const results = [];
    this.json.show.programmes.forEach(programme => {
      if (
        !programmeSlug ||
        programme.slug.toLowerCase() == programmeSlug.toLowerCase()
      ) {
        programme.units.forEach(unit => {
          results.push(unit);
        });
      }
    });
    return results;
  }

  public getGroupsByProgramme(programmeSlug: string = null) {
    const resultsObj = {};
    this.json.show.programmes.forEach(programme => {
      if (
        !programmeSlug ||
        programme.slug.toLowerCase() == programmeSlug.toLowerCase()
      ) {
        programme.units.forEach(unit => {
          unit.projects.forEach(project => {
            if (project.groupValue) {
              resultsObj[project.groupValue] = {
                value: project.groupValue,
                name: project.groupName
              };
            }
          });
        });
      }
    });

    const results = [];
    for (let resultsObjKey in resultsObj) {
      results.push(resultsObj[resultsObjKey]);
    }
    return results; //.sort((a, b) => (a.title > b.title) ? 1 : -1)
  }

  public getProjectsByStudent(studentName: string = null) {
    const results = [];
    if (studentName) {
      this.getProjectsByProgrammeAndUnit().forEach(project => {
        project.students.forEach(student => {
          if (!studentName || student.name == studentName) {
            const projectClone = Object.assign({}, project);
            projectClone.students = [student];
            results.push(projectClone);
          }
        });
      });
    }
    return results;
  }

  public getAwardTypes() {
    const results = [];
    this.getProjectsByProgrammeAndUnit().forEach(project => {
      //add student awards
      project.students.forEach(student => {
        if (student.awards) {
          student.awards.forEach(award => {
            if (results.indexOf(award.type) === -1) {
              results.push(award.type);
            }
          });
        }
      });
      //add project awards
      if (project.awards) {
        project.awards.forEach(award => {
          if (results.indexOf(award.type) === -1) {
            results.push(award.type);
          }
        });
      }
    });
    return results;
  }

  public getProjectsByTag(tagSearch: string = null) {
    const results = [];
    this.json.show.programmes.forEach(programme => {
      programme.units.forEach(unit => {
        unit.projects.forEach(project => {
          project.tags.forEach(tag => {
            if (tag.name === tagSearch) {
              results.push(project);
            }
          });
        });
      });
    });
    return results;
  }
}

export const showData = new ShowData();
