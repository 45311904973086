import gsap from "gsap";
import { EditorialModule } from "./EditorialModule";
import { WindowManager } from "../../utils/WindowManager";
import { clamp } from "../../utils/Helpers";

export class SlideshowModule extends EditorialModule {
  private dummyHeightEl: HTMLElement;
  private imagesContainerEl: HTMLElement;
  private images: NodeList;
  private currentScrollPosition: number = 0;
  private targetScrollPosition: number = 0;
  private totalScrollWidth: number = 0;

  constructor(element: HTMLElement) {
    super(element);
    this.dummyHeightEl = this.element.querySelector(".dummy-height");
    this.imagesContainerEl = this.element.querySelector(".images");
    this.images = this.element.querySelectorAll(".images li");
  }

  onResize() {
    this.images.forEach((image: HTMLElement) => {
      const width = image.clientHeight / Number(image.dataset.ar);
      image.style.width = width + "px";
    });
    this.totalScrollWidth =
      this.imagesContainerEl.clientWidth - WindowManager.width;
    if (this.totalScrollWidth > 0) {
      this.dummyHeightEl.style.height =
        this.imagesContainerEl.clientWidth + "px";
    } else {
      this.dummyHeightEl.style.height = WindowManager.height + "px";
    }
  }

  onScroll(scrollTop: number) {
    let pct =
      (scrollTop - this.element.offsetTop - WindowManager.height) /
      (this.dummyHeightEl.clientHeight - WindowManager.height);
    pct = clamp(0, 1, pct);
    this.targetScrollPosition = pct * -this.totalScrollWidth;
  }

  render() {
    if (this.totalScrollWidth > 0) {
      this.currentScrollPosition +=
        (this.targetScrollPosition - this.currentScrollPosition) / 4;
    } else {
      this.currentScrollPosition = 0;
    }

    this.imagesContainerEl.style.transform =
      "translateX(" + this.currentScrollPosition + "px)";
  }
}
