//@ts-ignore
export const BASE_PATH = window.BASE_PATH;
export const FIRST_ROUTE = "first_route";

export const OFF_BLACK_HEX = "#131214";
export const OFF_BLACK_DEC = 0x131214;

export const LIGHT = "light";
export const DARK = "dark";

export const OVERLAY_LAYER = ".overlay-layer";

export const CURRENT_3D_VIEW = "current_3d_view";
export const LAST_3D_PATH = "last_3d_Path";
export const ROOM = "room";
export const UNIT_BROWSER = "unit_browser";
export const CURRENT_OVERLAY = "current_overlay";
export const LAST_OVERLAY = "last_overlay";

export const ABOUT = "about";
export const EVENTS = "events";
export const PROJECT_INDEX = "project_index";
export const UNIT_PAGE = "unit_page";
export const PROJECT_PAGE = "project_page";
export const PROGRAMMES = "programmes";
export const CONTACT = "contact";

export const PANO_ACTIVATE = "event_pano_activate";
export const CLIENT_MOVE = "client-move";
export const LOAD_PROGRESS = "load_progress";
export const LOAD_COMPLETE = "load_complete";

export const NEXT_PROJECT_PAGE = "next-project-page";
export const PREV_PROJECT_PAGE = "prev-project-page";
export const VIDEO_URL_MAP = "video-url-map";

export const RENDERER = "renderer";
