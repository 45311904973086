import { EditorialModule } from "./EditorialModule";
import {
  BackSide,
  Material,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Scene,
  SphereGeometry,
  Texture,
  Vector3,
  WebGLRenderer
} from "three";
import * as THREE from "three";
import { WindowManager } from "../../utils/WindowManager";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { state } from "../../Main";
import { PANO_ACTIVATE, RENDERER } from "../../utils/Contants";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { isTouch, offset } from "../../utils/Helpers";
import { gallery3d } from "../gallery/Gallery3d";

const PANO_RENDERER = "panoRenderer";
const PANO_TYPE_SPHERICAL = "spherical";
const PANO_TYPE_HORIZONTAL = "horizontal";

export class PanoramaModule extends EditorialModule {
  private renderer: WebGLRenderer;
  private camera: PerspectiveCamera;
  private scene: Scene;
  private texture: Texture;
  private material: Material;
  private mesh: Mesh;
  private webglContainer: HTMLElement;
  private closeButton: HTMLElement;
  private posterImage: HTMLElement;
  private loadingEl: HTMLElement;
  private orbitControls: OrbitControls;
  private enabled: boolean;
  private loaded: boolean;
  private panoImageUrl: string;
  private panoType: string;

  constructor(element: HTMLElement) {
    super(element);

    this.webglContainer = this.element.querySelector(".webgl");
    this.closeButton = this.element.querySelector(".close");
    this.loadingEl = this.element.querySelector(".loading");
    this.posterImage = this.element.querySelector(".poster-image");
    this.webglContainer.style.display = "none";
    this.panoImageUrl = this.element.dataset.src;
    this.panoType = this.element.dataset.type;

    this.posterImage.addEventListener("click", () => {
      this.activate();
    });
    this.closeButton.addEventListener("click", () => {
      this.deactivate();
    });
  }

  public activate() {
    this.emit(PANO_ACTIVATE, {});
    this.enabled = true;

    if (!this.renderer) {
      this.renderer = state.getValue(RENDERER);
    }

    gsap.registerPlugin(ScrollToPlugin);

    gsap.to(this.loadingEl, 0.5, {
      autoAlpha: 1,
      onComplete: () => {
        if (!this.scene) {
          this.texture = new THREE.TextureLoader().load(
            this.panoImageUrl,
            () => {
              this.loaded = true;
              this.create3dScene();
            }
          );
        }

        this.webglContainer.append(this.renderer.domElement);
        this.pageCloseButtonEl.style.display = "none";
        this.closeButton.style.display = "block";
        this.webglContainer.style.display = "block";
        gsap.set(this.pageEl, {
          scrollTo: offset(this.element, this.pageEl).top
        });
        gsap.to(this.loadingEl, 0.5, { autoAlpha: 0 });
      }
    });
  }

  public deactivate() {
    this.enabled = false;
    gsap.to(this.loadingEl, 0.5, {
      autoAlpha: 1,
      onComplete: () => {
        this.pageCloseButtonEl.style.display = "block";
        this.closeButton.style.display = "none";
        this.webglContainer.style.display = "none";
        gallery3d.attachRenderer();
        //gallery3d.render();
        gsap.to(this.loadingEl, 0.5, { autoAlpha: 0 });
      }
    });
  }

  private async create3dScene() {
    this.scene = new Scene();

    this.camera = new PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.01,
      100
    );
    this.camera.lookAt(new Vector3(0, 0, 10));

    this.orbitControls = new OrbitControls(
      this.camera,
      this.renderer.domElement
    );
    this.orbitControls.enableZoom = false;
    this.orbitControls.enableDamping = true;
    this.orbitControls.dampingFactor = isTouch() ? 0.5 : 0.05; // friction
    this.orbitControls.rotateSpeed = isTouch() ? -0.5 : -0.2; // mouse sensitivity

    this.material = new MeshBasicMaterial({
      map: this.texture,
      side: BackSide
    });

    if (this.panoType == PANO_TYPE_HORIZONTAL) {
      this.camera.fov = 30;
      this.camera.updateProjectionMatrix();
      this.camera.position.z = 1;

      this.orbitControls.minAzimuthAngle = -0.9;
      this.orbitControls.maxAzimuthAngle = 0.9;
      this.orbitControls.minPolarAngle = Math.PI / 2 - 0.14;
      this.orbitControls.maxPolarAngle = Math.PI / 2 + 0.14;

      const obj: any = await this.loadMesh();
      this.mesh = obj.scene.children[0];
      this.mesh.scale.y = -1;
      this.mesh.material = this.material;
    } else if (this.panoType == PANO_TYPE_SPHERICAL) {
      this.camera.position.z = 0.1;
      this.orbitControls.autoRotate = true;
      this.orbitControls.autoRotateSpeed = 0.2;
      this.mesh = new Mesh(new SphereGeometry(20, 20, 20), this.material);
      this.mesh.scale.x = -1;
    }
    this.scene.add(this.mesh);
    this.webglContainer.style.display = "block";
    this.onResize();
  }

  public loadMesh() {
    return new Promise(resolve => {
      const gltf_loader = new GLTFLoader();
      gltf_loader.load("/assets/models/semisphere.glb", (obj: any) => {
        resolve(obj);
      });
    });
  }

  public render() {
    if (this.enabled && this.loaded) {
      this.renderer.render(this.scene, this.camera);
      this.orbitControls.update();
    }
  }

  public onResize() {
    if (this.renderer) {
      this.renderer.setSize(WindowManager.width, WindowManager.height);
    }
  }

  public destroy() {
    super.destroy();
    if (this.texture) {
      this.texture.dispose();
    }
    if (this.material) {
      this.material.dispose();
    }
    if (this.scene) {
      this.scene.dispose();
    }
  }
}
