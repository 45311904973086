import gsap from "gsap";
import { EditorialPage } from "./EditorialPage";
import { SlidePage } from "./SlidePage";
import { WindowManager } from "../utils/WindowManager";
import { colorizer } from "../modules/Colorizer";

export class UnitPage extends EditorialPage {
  constructor(element: HTMLElement) {
    super(element);
  }

  public animateIn() {
    if (this.animatedIn) {
      return;
    }
    this.animatedIn = true;
    this.show();

    return new Promise(resolve => {
      gsap.set(this.element, { y: WindowManager.height });
      gsap.to(this.element, 0.7, {
        y: 0,
        clearProps: "y",
        ease: "Power2.easeInOut",
        onComplete: () => {
          resolve();
          this.animateInComplete();
        }
      });
    });
  }

  public animateOut() {
    if (!this.animatedIn) {
      return;
    }
    if (this.headerColor) {
      colorizer.changeColorBack();
    }
    this.animatedIn = false;
    return new Promise(resolve => {
      gsap.to(this.element, 0.7, {
        y: WindowManager.height,
        ease: "Power2.easeInOut",
        onComplete: () => {
          resolve();
          this.hide();
          this.animateOutComplete();
        }
      });
    });
  }
}
